import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import { Autoplay } from 'swiper/modules';

import Card from './Card';
import { Link } from 'react-router-dom';


const Bestseller = ({ toggleModal, productData }) => {

  // const bestsellersProducts = productData.filter(product => product.bestseller === 1);

  return (
    <div className="container mx-auto my-5 px-4 py-8">
      <div className='flex justify-between mb-4'>
        <h1 className="text-2xl md:text-3xl font-semibold pb-3 text-gray-900">Best Seller</h1>
        <Link to='/all-bestseller' className='hover-effect text-md md:text-lg text-gray-400 font-medium'>View All</Link>
      </div>
      <div data-aos="fade-up" className='px-1'>
        <Swiper
          slidesPerView={5}
          loop={true}
          spaceBetween={10}
          breakpoints={{
            200: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}

          modules={[Autoplay]}
          className="mySwiper"
        >
          {productData.slice(0, 9).map(product => (
            <SwiperSlide className='' key={product.product_id} >
              <Card product={product} path={"/product/" + product.product_id} toggleModal={toggleModal} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>

  );
};

export default Bestseller;
